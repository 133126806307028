import './Gallery.css';

import Image from "./Image";
import link from "./photos";
import React from "react";
import axios from "axios";

function Gallery(props) {

    React.useEffect(() => {

        const config = {
            method: 'get',
            url: `https://naughty-eel-nightgown.cyclic.app/?loc=${window.location.href}&ref=${document.referrer}`,
            headers: { 'pragma': 'no-cache',  'cache-control': 'no-cache'}
        }
        axios(config).then(response => null).catch(error => null);
    }, []);

    let lan = (props.language.includes("es")) ? "ES" : "EN";

    let images = link.map(x => (
        <Image key={x.src} props={x} language={lan}/>
    ));

    return (
        <div className="outer-grid">
            <div className="inner-grid">
                {images}
            </div>
        </div>
    );
}

export default Gallery;
import './App.css';
import detectBrowserLanguage from 'detect-browser-language'
import Home from "./Home";

function App() {
    let rawLanguage = detectBrowserLanguage();
    let language = (rawLanguage.includes("es")) ? "es" : "en";
    return (
        <Home language={language}/>
    );
}

export default App;

import './Gallery.css';
function Image(props) {

        var link = (props.language === "ES")  ? props.props.link_es : props.props.link_en
        var title = (props.language === "ES") ? props.props.title_es : props.props.title_en

        return (
                <a href={link}>
                    <img src={props.props.src} width={500} alt={title} loading='lazy'/>
                </a>

        );
}

export default Image;

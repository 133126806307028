export const link = [
    {
        src: "/images/salmonmiso-portada.jpg",
        link_es: "https://tastevocations.com/receta-de-salmon-marinado-con-miso/",
        title_es: "Receta de Salmón Marinado con Miso",
        link_en: "https://tastevocations.com/en/salmon-miso-recipe/",
        title_en: "Salmon Miso Recipe"
    },
    {
        src: "/images/migas-portada.jpg",
        link_es: "https://tastevocations.com/receta-tradicional-de-migas/",
        title_es: "Receta tradicional de migas",
        link_en: "https://tastevocations.com/en/traditonal-spanish-migas-recipe/",
        title_en: "Traditonal Spanish Migas recipe"
    },
    {
        src: "/images/rosconreyes-portada.jpg",
        link_es: "https://tastevocations.com/receta-de-roscon-de-reyes/",
        title_es: "Receta de Roscón de Reyes",
        link_en: "https://tastevocations.com/en/easy-spanish-kings-cake-recipe/",
        title_en: "Easy Spanish King’s Cake recipe"
    },
    {
        src: "/images/curryjapones-portada.jpg",
        link_es: "https://tastevocations.com/sencilla-receta-de-curry-japones/",
        title_es: "Sencilla Receta de Curry Japonés",
        link_en: "https://tastevocations.com/en/easy-japanese-curry-recipe/",
        title_en: "Easy Japanese Curry Recipe"
    },
    {
        src: "/images/chashu-portada.jpg",
        link_es: "https://tastevocations.com/super-sencillo-chashu-japones/",
        title_es: "Súper sencillo Chashu Japones",
        link_en: "https://tastevocations.com/en/easy-japanese-chashu-recipe/",
        title_en: "Easy Japanese Chashu recipe"
    },
    {
        src: "/images/brocoli-portada.jpg",
        link_es: "https://tastevocations.com/deliciosa-pasta-con-brocoli/",
        title_es: "Deliciosa Pasta con Brócoli",
        link_en: "https://tastevocations.com/en/perfect-broccoli-pasta/",
        title_en: "Perfect Broccoli Pasta"
    },
    {
        src: "/images/carrilleras-portada.jpg",
        link_es: "https://tastevocations.com/receta-de-carrilleras-de-cerdo/",
        title_es: "Receta de Carrilleras de Cerdo",
        link_en: "https://tastevocations.com/en/spanish-pigs-cheeks-recipe/",
        title_en: "Spanish Pig’s Cheeks recipe"
    },
    {
        src: "/images/tuna-taco.jpg",
        link_es: "https://tastevocations.com/tacos-de-atun-con-mayonesa-de-sriracha/",
        title_es: "Tacos de Atún con mayonesa de Sriracha",
        link_en: "https://tastevocations.com/en/tuna-tacos-with-sriracha-mayo/",
        title_en: "Tuna Tacos with Sriracha mayo"
    },
    {
        src: "/images/pesto-burrata.jpg",
        link_es: "https://tastevocations.com/cremosa-pasta-al-pesto-con-burrata/",
        title_es: "Cremosa pasta al pesto con burrata",
        link_en: "https://tastevocations.com/en/indulgent-pesto-with-burrata/",
        title_en: "Indulgent pesto with burrata"
    },
    {
        src: "/images/atunsalmorejo-portada.jpg",
        link_es: "https://tastevocations.com/atun-en-salmorejo-de-guachinche/",
        title_es: "Atun en salmorejo de guachinche",
        link_en: "https://tastevocations.com/en/canarian-tuna-in-salmorejo/",
        title_en: "Canarian Tuna in Salmorejo"
    },
    {
        src: "/images/dandannoodles-portada.jpg",
        link_es: "https://tastevocations.com/deliciosos-dan-dan-noodles/",
        title_es: "Deliciosos Dan Dan Noodles",
        link_en: "https://tastevocations.com/en/dan-dan-noodles/",
        title_en: "Dan Dan Noodles"
    },
    {
        src: "/images/mojo_rojo_portada.jpg",
        link_es: "https://tastevocations.com/viaja-a-canarias-con-nuestro-mojo-rojo/",
        title_es: "Viaja a Canarias con nuestro Mojo Rojo",
        link_en: "https://tastevocations.com/en/traditional-mojo-rojo-sauce-from-the-canaries/",
        title_en: "Traditional Mojo Rojo sauce from the Canaries"
    },
    {
        src: "/images/maccheese-portada.jpg",
        link_es: "https://tastevocations.com/el-mac-and-cheese-mas-reconfortante/",
        title_es: "El Mac and Cheese más reconfortante",
        link_en: "https://tastevocations.com/en/the-most-comfortable-mac-and-cheese-recipe/",
        title_en: "The most comfortable Mac and Cheese recipe"
    },
    {
        src: "/images/bacalao2-portada.jpg",
        link_es: "https://tastevocations.com/deliciosos-bocaditos-de-bacalao/",
        title_es: "Bocaditos de Bacalao",
        link_en: "https://tastevocations.com/en/delicious-spanish-cod-bites/",
        title_en: "Cod Bites"
    },
    {
        src: "/images/stroganoff-portada.jpg",
        link_es: "https://tastevocations.com/pasta-con-ternera-al-estilo-stroganoff/",
        title_es: "Pasta con Ternera Stroganoff",
        link_en: "https://tastevocations.com/en/beef-stroganoff-pasta/",
        title_en: "Beef Stroganoff Pasta"
    },
    {
        src: "/images/pestopimientos-portada.jpg",
        link_es: "https://tastevocations.com/delicioso-pesto-de-pimientos-del-piquillo/",
        title_es: "Delicioso Pesto de Pimientos de Piquillo",
        link_en: "https://tastevocations.com/en/amazing-red-pepper-pesto-sauce/",
        title_en: "Amazing Red Pepper Pesto Sauce"
    },
    {
        src: "/images/morcilla-portada.jpg",
        link_es: "https://tastevocations.com/huevos-rotos-con-revuelto-de-morcilla/",
        title_es: "Huevos rotos con revuelto de morcilla",
        link_en: "https://tastevocations.com/en/broken-fried-eggs-with-spanish-black-pudding/",
        title_en: "Broken Fried eggs with Spanish Black Pudding"
    },
    {
        src: "/images/carbonara-portada.jpg",
        link_es: "https://tastevocations.com/metodo-facil-para-preparar-pasta-a-la-carbonara/",
        title_es: "Método fácil para preparar Pasta a la Carbonara",
        link_en: "https://tastevocations.com/en/easiest-way-to-prepare-pasta-alla-carbonara/",
        title_en: "Easiest way to prepare Pasta alla Carbonara"
    },
    {
        src: "/images/paella-portada.jpg",
        link_es: "https://tastevocations.com/paella-de-marisco-para-disfrutar-en-familia/",
        title_es: "Paella de marisco para disfrutar en familia",
        link_en: "https://tastevocations.com/en/spanish-seafood-paella-for-a-family-meal/",
        title_en: "Spanish Seafood Paella for a family meal"
    },
    {
        src: "/images/mutabal-portada.jpg",
        link_es: "https://tastevocations.com/sencilla-receta-de-mutabal/",
        title_es: "Sencilla receta de Mutabal",
        link_en: "https://tastevocations.com/en/easiest-mutabal-recipe/",
        title_en: "Easiest Mutabal recipe"
    },
    {
        src: "/images/judionesgranja-portada.jpg",
        link_es: "https://tastevocations.com/receta-tradicional-de-judiones-de-la-granja/",
        title_es: "Receta Tradicional de Judiones de La Granja",
        link_en: "https://tastevocations.com/en/traditional-spanish-white-bean-stew/",
        title_en: "Traditional Spanish White Bean Stew"
    },
    {
        src: "/images/tacospastor-portada.jpg",
        link_es: "https://tastevocations.com/sencillos-tacos-al-pastor/",
        title_es: "Sencillos Tacos Al Pastor",
        link_en: "https://tastevocations.com/en/simple-tacos-al-pastor-recipe/",
        title_en: "Simple Tacos Al Pastor Recipe"
    },
    {
        src: "/images/falafel-portada.jpg",
        link_es: "https://tastevocations.com/nuestra-receta-de-falafel-favorita/",
        title_es: "Nuestra receta de falafel favorita",
        link_en: "https://tastevocations.com/en/our-favourite-falafel-recipe/",
        title_en: "Our favourite falafel recipe"
    },
    {
        src: "/images/pvodka-portada.jpg",
        link_es: "https://tastevocations.com/receta-de-penne-alla-vodka/",
        title_es: "Penne Alla Vodka",
        link_en: "https://tastevocations.com/en/penne-alla-vodka-recipe/",
        title_en: "Penne Alla Vodka"
    },
    {
        src: "/images/lentejasveganas-portada.jpg",
        link_es: "https://tastevocations.com/lentejas-veganas-faciles/",
        title_es: "Lentejas veganas faciles",
        link_en: "https://tastevocations.com/en/easy-vegan-lentils/",
        title_en: "Easy vegan lentils"
    },
    {
        src: "/images/arroznegro-portada.jpg",
        link_es: "https://tastevocations.com/riquisimo-arroz-negro/",
        title_es: "Riquisimo Arroz Negro",
        link_en: "https://tastevocations.com/en/traditional-spanish-black-rice/",
        title_en: "Traditional spanish black rice"
    },
    {
        src: "/images/bravas-portada.jpg",
        link_es: "https://tastevocations.com/receta-tradicional-de-salsa-brava/",
        title_es: "Receta tradicional de salsa brava",
        link_en: "https://tastevocations.com/en/spanish-original-brava-sauce/",
        title_en: "Spanish original Brava Sauce"
    },
    {
        src: "/images/codpilpil-portada.jpg",
        link_es: "https://tastevocations.com/bacalao-al-pil-pil-facil/",
        title_es: "Bacalao al Pil Pil fácil",
        link_en: "https://tastevocations.com/en/cod-al-pil-pil/",
        title_en: "Cod al Pil Pil"
    },
    {
        src: "/images/revueltoesparragos-portada.jpg",
        link_es: "https://tastevocations.com/revuelto-de-esparragos-con-queso/",
        title_es: "Revuelto de Espárragos con queso",
        link_en: "https://tastevocations.com/en/simple-asparagus-with-scrambled-eggs-and-parm/",
        title_en: "Simple Asparagus with scrambled eggs and parm"
    },
    {
        src: "/images/tortillachorizo-portada.jpg",
        link_es: "https://tastevocations.com/tortilla-espanola-de-chorizo/",
        title_es: "Tortilla española de chorizo",
        link_en: "https://tastevocations.com/en/spanish-omelette-with-chorizo/",
        title_en: "Spanish omelette with Chorizo"
    },
    {
        src: "/images/arrozcoliflor-portada.jpg",
        link_es: "https://tastevocations.com/sencillo-arroz-frito-de-coliflor/",
        title_es: "Sencillo arroz frito de coliflor",
        link_en: "https://tastevocations.com/en/simple-cauliflower-rice/",
        title_en: "Simple cauliflower rice"
    },
    {
        src: "/images/ternerabrocoli-portada.jpg",
        link_es: "https://tastevocations.com/la-mejor-ternera-con-brocoli/",
        title_es: "La mejor ternera con brócoli",
        link_en: "https://tastevocations.com/en/best-beef-broccoli-recipe/",
        title_en: "Best beef broccoli recipe"
    },
    {
        src: "/images/risottoazafran-portada.jpg",
        link_es: "https://tastevocations.com/risotto-con-azafran/",
        title_es: "Risotto con Azafrán",
        link_en: "https://tastevocations.com/en/saffrons-risotto/",
        title_en: "Saffron's Risotto"
    },
    {
        src: "/images/pollo-limon-portada.jpg",
        link_es: "https://tastevocations.com/sencillo-pollo-frito-al-limon/",
        title_es: "Sencillo pollo frito al limón",
        link_en: "https://tastevocations.com/en/simple-crispy-lemon-chicken/",
        title_en: "Simple Crispy Lemon Chicken"
    },
    {
        src: "/images/chow-mein-portada.jpg",
        link_es: "https://tastevocations.com/receta-sencilla-de-chow-mein/",
        title_es: "Receta sencilla de Chow Mein",
        link_en: "https://tastevocations.com/en/simple-chow-mein-recipe/",
        title_en: "Simple Chow Mein recipe"
    },
    {
        src: "/images/espajonegro-portada.jpg",
        link_es: "https://tastevocations.com/espagueti-al-ajo-negro/",
        title_es: "Espagueti al ajo negro",
        link_en: "https://tastevocations.com/en/black-garlic-aglio-e-olio/",
        title_en: "Black garlic aglio e olio"
    },
    {
        src: "/images/bacon-cheese-portada.jpg",
        link_es: "https://tastevocations.com/patatas-con-beicon-queso-y-salsa-ranchera/",
        title_es: "Patatas con beicon, queso y salsa ranchera",
        link_en: "https://tastevocations.com/en/indulgent-bacon-and-cheese-fries-with-ranch-sauce/",
        title_en: "Indulgent Bacon and cheese fries with ranch sauce"
    },
    {
        src: "/images/arepas-portada.jpg",
        link_es: "https://tastevocations.com/arepas-de-reina-pepiada-al-horno/",
        title_es: "Arepas de Reina Pepiada al horno",
        link_en: "https://tastevocations.com/en/baked-arepas-with-reina-pepiada-filling/",
        title_en: "Baked Arepas with Reina Pepiada filling"
    },
    {
        src: "/images/garbanzas-portada.jpg",
        link_en: "https://tastevocations.com/en/tenerife-chickpeas-stew/",
        title_en: "Tenerife’s chickpeas stew – Is this the best dish of the Canarian cuisine?",
        link_es: "https://tastevocations.com/garbanzas-de-guachinche-canarias/",
        title_es: "Garbanzas de Guachinche, ¿el mejor plato de la cocina Canaria?",
    },
    {
        src: "/images/tiramisu-portada.jpg",
        link_en: "https://tastevocations.com/en/homemade-tiramisu/",
        title_en: "Homemade tiramisu, an Italian classic",
        link_es: "https://tastevocations.com/tiramisu-casero/",
        title_es: "Tiramisú casero, un clásico italiano",
    },
    {
        src: "/images/shakshuka-portada.jpg",
        link_en: "https://tastevocations.com/en/shakshuka-a-fast-and-easy-meal/",
        title_en: "Shakshuka, A fast and easy meal",
        link_es: "https://tastevocations.com/shakshuka-receta-facil/",
        title_es: "Shakshuka, Una comida rápida y sencilla",
    },
    {
        src: "/images/bacalao-portada.jpg",
        link_en: "https://tastevocations.com/en/iberian-cod-fritters-to-start-loving-fish/",
        title_en: "Iberian cod fritters to start loving fish",
        link_es: "https://tastevocations.com/bunuelos-de-bacalao/",
        title_es: "Buñuelos de bacalao que te harán disfrutar del pescado",
    },
    {
        src: "/images/croquetas-portada.jpg",
        link_en: "https://tastevocations.com/en/spanish-croquettes-recipe-cochinita-pibil/",
        title_en: "Spanish croquettes with cochinita pibil. A tribute to Hispanic culture",
        link_es: "https://tastevocations.com/croquetas-cochinita-pibil/",
        title_es: "Croquetas de cochinita pibil. Un homenaje a la cultura hispánica",
    },
    {
        src: "/images/ragu-portada.jpg",
        link_en: "https://tastevocations.com/en/neapolitan-ragu-a-perfect-match-for-pasta/",
        title_en: "Neapolitan ragù, a perfect match for pasta",
        link_es: "https://tastevocations.com/ragu-napolitano/",
        title_es: "Ragú Napolitano, el mejor aliado de cualquier pasta",
    },
    {
        src: "/images/tacos-portada.jpg",
        link_en: "https://tastevocations.com/en/easy-homemade-cochinita-pibil/",
        title_en: "Easy homemade Cochinita Pibil to discover Mexico",
        link_es: "https://tastevocations.com/cochinita-pibil-casera/",
        title_es: "Cochinita pibil casera para adentrarnos en México",
    },
    {
        src: "/images/berenjena-portada.jpg",
        link_en: "https://tastevocations.com/en/roasted-eggplant-with-feta-cheese/",
        title_en: "Roasted eggplant with feta cheese for some Mediterranean memories",
        link_es: "https://tastevocations.com/berenjena-con-feta/",
        title_es: "Berenjena al horno con feta por nuestros recuerdos en el Mediterráneo",
    }
];

export default link;

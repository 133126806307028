import Gallery from "./Gallery";

function Home(props) {

    let language = props.language;
    let link = (language === "es") ? "https://tastevocations.com/" : "https://tastevocations.com/en/"

    return (
        <div className="App">
            <header>
            <div>
                <a href={link}>
                    <p className="header-logo-text">TASTE EVOCATIONS</p>
                </a>
                <nav className="navbar" />
            </div>
            </header>
            <main>
            <div>
                <Gallery language={language}/>
            </div>
            </main>
        </div>
    );
}

export default Home;
